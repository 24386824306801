.Home {

  .header-main{
    // background: no-repeat center/cover url("../img/home/Horizon_38.jpg");
    // animation: fadeinbg 1s linear infinite;
    // background-color: black;
    // z-index: 9999999 !important;
  }
  .carousel, .carousel-slider{
    background-color: rgb(0, 2, 15);
  }

  .carousel-image{
    
    object-fit: cover;
    position: center;
    opacity: 0.7;
  }

  @media only screen and (max-width: 480px) {
    .carousel-image{
      height: 50vh;
    }
  }
  @media only screen and (min-width: 480px){
    .carousel-image{
      height: 80vh;
    }
  }

  @keyframes fadeinbg{
    // 0%{
    //   background: no-repeat center/cover url("../img/home/Horizon_38.jpg");
    // }
    // 17%{
    //   background: no-repeat center/cover url("../img/home/Horizon_38.jpg");
    // }
    // 20%{
    //   background: no-repeat center/cover url("../img/home/DSC06544.jpg");
    // }
    // 37%{
    //   background: no-repeat center/cover url("../img/home/DSC06544.jpg");
    // }
    // 40%{
    //   background: no-repeat center/cover url("../img/home/Horizon_5.jpg");
    // }
    // 57%{
    //   background: no-repeat center/cover url("../img/home/Horizon_5.jpg");
    // }
    // 60%{
    //   background: no-repeat center/cover url("../img/home/DSC06539-HDR.jpg");
    // }
    // 77%{
    //   background: no-repeat center/cover url("../img/home/DSC06539-HDR.jpg");
    // }
    // 80%{
    //   background: no-repeat center/cover url("../img/home/DSC06418.jpg");

    // }
    // 97%{
    //   background: no-repeat center/cover url("../img/home/DSC06418.jpg");

    // }
    // 100%{
    //   background: no-repeat center/cover url("../img/home/Horizon_38.jpg");
    // }
  }
  p {
    line-height: 1.6;
  }

  .spotlight {
    margin-top: 5rem;
    padding: 0 3rem;
    h1 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .card-row {
      display: flex;
      .card {
        width: 100%;
        background: rgba(1, 78, 243, 0.048);
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        .btn {
          border: none;
        }
        img {
          width: 100%;
          height: 300px;
        }

        p {
          margin: 1rem 0;
        }
      }
    }
  }
}

/* Tablet/Mobile */
@media only screen and (max-width: 1100px) {
  .Home {
    .spotlight {
      .card-row {
        flex-direction: column;
        .card {
          margin: 1rem 0;
          padding: 1rem;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Home {
    .spotlight {
      padding: 0 1rem;
    }
  }
}
