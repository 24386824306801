.Checkout {
  .room-info {
    background-color: #dbd5cd;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      justify-content: space-between;
      width: 40%;
      padding: 1rem 0;
    }
  }
  .details {
    max-width: 1000px;
    margin: 2rem auto;
    h1 {
      font-size: 2rem;
      text-align: center;
    }

    .error-msg {
      display: inline-block;
      text-align: center;
      width: 100%;
      color: crimson;
    }
    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 3rem;
      .outlined-basic {
        flex-basis: 45%;
        margin: 1rem 0;
      }
      .btn-container {
        margin: 2rem 0 0 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Checkout {
    .room-info {
      width: auto;
      margin: 0 1rem;
      div {
        align-items: baseline;
        width: 100%;
        flex-direction: column;
      }
    }
    .details {
      form {
        flex-direction: column;
        align-items: center;
        padding: 0;
      }
    }
  }
}
