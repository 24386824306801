.Footer {
  background-color: black;
  color: white;
  margin-top: 2rem;
  text-align: center;
  padding: 1rem 0;
  h2 {
    font-size: 2rem;
    border-bottom: 1px solid #333333;
    width: 90%;
    margin: auto;
    padding-bottom: 1rem;
  }
  p {
    padding-top: 1rem;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .Footer {
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 12px;
    }
  }
}
