.About {
  img {
    width: 100%;
  }

  .desc {
    h1 {
      margin-bottom: 0;
    }
    span {
      border-bottom: 1px solid black;
      width: 30%;
      display: inline-block;
      margin: 1rem auto;
    }
  }
  .desc_photo {
    .descRight {
      top: -45px;
    }
  }
  .testimonials {
    max-width: 1200px;
    margin: 8rem auto 0 auto;
    h1 {
      font-size: 3rem;
      text-align: center;
    }
    &-card {
      display: flex;
      position: relative;
      margin: 4rem 0;
      .img-container {
        position: absolute;
        top: -30px;
        left: -30px;
        width: 25%;
      }
      .quote-container {
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 3rem 0 19rem;
        height: 17rem;

        p {
          line-height: 1.5;
        }

        span {
          font-weight: bold;
          margin: 1rem 0;
        }
      }
    }
    .reversed {
      .img-container {
        right: -30px;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
      }
      .quote-container {
        padding: 0 19rem 0 3rem;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .About {
    .testimonials {
      padding: 1rem;
      &-card {
        display: grid;
        grid-template-columns: 2fr 3fr;
        align-items: center;
        .quote-container {
          padding: 1rem;
          margin-left: 1rem;
          height: 100%;
        }
        .img-container {
          position: relative;
          width: 100%;
          flex-basis: 40%;
          top: 0;
          left: 0;
        }
      }
      .reversed {
        .quote-container {
          padding: 1rem;
          margin-left: 1rem;
          height: 100%;
        }
        .img-container {
          position: relative;
          transform: none;
          width: 100%;
          flex-basis: 40%;
          right: 0;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .About {
    .desc_photo {
      .descRight {
        top: 0;
      }
    }
    .testimonials {
      margin: 0;
      &-card {
        grid-template-columns: 1fr;
        .quote-container {
          margin: 0;
        }
      }
      .reversed {
        .quote-container {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .About {
    .testimonials {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
