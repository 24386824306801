.BookWidget {
  .mobile-toggle {
    display: none;
    font-size: 1.8rem;
    position: absolute;
    bottom: 0;
  }
  i:hover {
    cursor: pointer;
  }
  background-color: white;
  position: absolute;
  top: 85%;
  width: 92%;
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem 0;
  z-index: 9999999 !important;
  transition: all ease 200ms;
  form {
    display: flex;
    justify-content: space-between;
    margin: 0 3rem;
    align-items: center;
    .date {
      display: flex;
      .mobile-toggle {
        display: none;
      }
      div {
        &:hover {
          cursor: pointer;
        }
        label {
          display: inline-block;
          margin-bottom: 8px;
        }
        span {
          padding: 0 1rem;
        }
      }
    }
    .guest {
      display: flex;
      .adults {
        margin-right: 2rem;
      }
      .guest-select {
        margin-top: 10px;
        display: flex;
        align-items: center;
        span {
          padding: 0 1rem;
        }
      }
    }
  }
}

/* Large Screen */
@media only screen and (max-width: 1100px) {
  .BookWidget {
    width: 97%;
    form {
      margin: 0 1rem;
    }
  }
}

/* Book Widget tablet/mobile */
@media only screen and (max-width: 920px) {
  .BookWidget {
    padding: 1.4rem 0;
    height: 120px;
    overflow: hidden;
    .mobile-toggle {
      display: block;
    }
    form {
      flex-direction: column;
      .date {
        align-items: center;

        .mobile-toggle {
          display: inline-block;
          padding-left: 12px;
          font-size: 1.5rem;
        }
      }
      .guest {
        margin: 2.5rem 0 2rem 0;
      }
    }
  }
  .active {
    height: 320px !important;
    background: white;
    z-index: 99 !important;
  }
}
