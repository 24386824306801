.Rooms {
  img {
    width: 100%;
    min-height: 250px;
  }
  .btn-alt {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
  }
  .desc {
    p {
      line-height: 1.5;
    }
  }

  .flex-row-lg {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem auto;
    padding: 0 4rem;
    .card {
      margin: 2rem 2rem 0 2rem;
      display: flex;
      flex-direction: column-reverse;
      flex: 1 0 40%;
      h1 {
        font-size: 2rem;
        font-weight: lighter;
      }
      p {
        margin: 1rem 0;
        line-height: 1.5;
      }
      a {
        width: 80px;
      }
      .img-container {
        overflow: hidden;
        max-height: 100%;
        max-width: 100%;
        img {
          transform: scale(1);
          transition: all ease-in-out 300ms;
        }
      }

      a:hover ~ .img-container img {
        transform: scale(1.1);
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .Rooms {
    .flex-row-lg {
      padding: 0;
    }
  }
}
@media only screen and (max-width: 768px) {
  .Rooms {
    .flex-row-lg {
      padding: 0 1rem;
      flex-wrap: nowrap;
      flex-direction: column;
      .card {
        margin: 2rem 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Rooms {
    img {
      min-height: 100%;
    }
    .flex-row-lg {
      flex-direction: column;
      .card {
        margin: 2rem 0 0 0;
      }
    }
  }
}
