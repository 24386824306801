.Room {
  img {
    width: 100%;
    min-height: 250px;
  }

  .roomInfo {
    max-width: 1200px;
    display: flex;
    margin: 3rem auto 0 auto;
    .infoLeft {
      flex-basis: 50%;
      display: flex;
      flex-wrap: wrap;
      .info {
        display: flex;
        flex-wrap: wrap;
        div {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          line-height: 2;
        }
      }
      .infoIcons {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 100px;
            margin: 10px 0;
            .itemIcon {
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: lighter;
              font-size: 1.6rem;
              border: 1px solid #e6e6e6;
              color: #88807e;
            }
            .itemShow:hover {
              cursor: pointer;
            }
            p {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .infoRight {
      flex-basis: 50%;
      h2 {
        font-weight: lighter;
        margin-bottom: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Room {
    .roomInfo {
      margin: 0;
      padding: 3rem 1rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Room {
    .desc_main {
      margin: 2rem 0 0 0;
    }
    .roomInfo {
      flex-direction: column;
      .infoLeft {
        .info {
          div {
            padding-right: 1rem;
          }
        }
        .infoIcons {
          ul {
            li {
              width: 80px;
            }
          }
        }
      }
      .infoRight {
        margin-top: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Room {
    .roomInfo {
      .infoLeft {
        .infoIcons {
          ul {
            li {
              width: 70px;
              .itemIcon {
                width: 45px;
                height: 45px;
              }
            }
          }
        }
      }
    }
  }
}
