* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // background: red;
}

body {
  // font-family: "Roboto", sans-serif;
  font-family: "Poppins", sans-serif;
  a {
    text-decoration: none;
    color: inherit;
  }

  li {
    list-style: none;
  }
}
