

.Booking {
  .project-info {
    padding: 100px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    top: -50px;
  }
  
  .project-title {
    font-weight: 500;
    font-size: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
  }
  
  .lighter {
    font-size: 0.9em;
  }
  
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .tag {
    font-weight: lighter;
    color: grey;
  }
  
  /*DELETE THIS TWO LINE*/
  .delete {
    background-color: #b2b2fd;
  }
  
  .card-img div {
    width: 90%;
  }
  /*IF USING IMAGES*/
  
  .card {
    background-color: white;
    color: black;
    width: 300px;
    max-height: 330px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .card-img {
    position: relative;
    top: -20px;
    height: 100px;
    display: flex;
    justify-content: center;
  }
  
  /* Change the .card-img div to .card-img img to use img*/
  .card-img a,
  .card-img div {
    height: 150px;
    width: 90%;
    /* Change this width here to change the width of the color/image */
    object-fit: cover;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .card-imgs {
    transition: all 0.5s;
  }

  
  .BookWidget {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    margin: auto;
  }
  img {
    width: 100%;
  }

  .existing {
    text-align: center;
    margin-top: 2rem;
    line-height: 2;
    display: flex;
    flex-direction: column;
    form {
      display: flex;
      justify-content: center;
      input {
        width: 300px;
        padding: 10px;
        margin-right: 10px;
        border: 1px solid black;
      }
    }
    .disclaimer {
      font-size: 14px;
      color: #000000ab;
    }
  }
  .desc {
    margin-top: 4rem;
  }

  .filler {
    min-height: 50vh;
  }
  .loader {
    margin-top: 3rem;
  }
}

/* Tablet/Mobile */
@media only screen and (max-width: 768px) {
  .Booking {
    .bookingResults {
      padding: 0 10px;
      .room-card {
        width: 100%;
        flex-wrap: wrap;
        .card-img {
          flex-basis: 100%;
          width: 100%;
        }
        .card-info {
          flex-basis: 40%;
          width: 40%;
        }
        .card-price {
          width: 60%;
          flex-basis: 60%;
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .Booking {
    .existing {
      form {
        flex-direction: column;
        margin: 0 1rem;
        input {
          width: auto;
          margin: 0 0 1rem 0;
        }
      }
    }
    .bookingResults {
      .room-card {
        .card-info {
          flex-basis: 100%;
          width: 100%;
          .amenities {
            ul {
              flex-wrap: wrap;
              line-height: 2;
            }
          }
        }
        .card-price {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 1rem;
          width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}
