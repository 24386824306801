/* Will have utlity classes here */
body{
  font-family: "Poppins", sans-serif;
}
.react-reveal{
  z-index: 2;
}

.center-inside{
  display: flex;
  justify-content: center;
}
.login-form-box {
  max-width: 300px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 16px;
  color: #010101;
  font-family: "Playfair Display", serif;


.form {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px;
  gap: 16px;
  text-align: center;
}

/*Form text*/
.title {
  font-weight: bold;
  font-size: 1.6rem;
}

.subtitle {
  font-size: 1rem;
  color: #666;
}

/*Inputs box*/
.form-container {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  margin: 1rem 0 .5rem;
  width: 100%;
}

.input {
  background: none;
  border: 0;
  outline: 0;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
  font-size: .9rem;
  padding: 8px 15px;
}

.form-section {
  padding: 16px;
  font-size: .85rem;
  background-color: #e0ecfb;
  box-shadow: rgb(0 0 0 / 8%) 0 -1px;
}

.form-section a {
  font-weight: bold;
  color: #000000;
  transition: color .3s ease;
}

.form-section a:hover {
  color: #000000;
  text-decoration: underline;
}

/*Button*/
.form button {
  background-color: #000000;
  color: #fff;
  border: 0;
  border-radius: 24px;
  font-family: "Playfair Display", serif;
  padding: 10px 16px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color .3s ease;
}

.form button:hover {
  background-color: #005ce6;
}

}

.contact-form {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  // width: 300px;
  width: 100%;
  margin: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 30px 30px -30px rgba(0, 0, 0, 0.774);
}

.contact-form .title {
  color: black;
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Playfair Display", serif;
  text-align: center;
  align-self: center;
}

.contact-form input {
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  padding: 8px 14px;
  font-size: 18px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  // border-radius: 8px;
  width: 100%;
  height: 50px;
}

.contact-form textarea {
  // border-radius: 8px;
  height: 200px;
  width: 100%;
  resize: none;
  outline: 0;
  font-family: "Playfair Display", serif;
  font-weight: bold;

  font-size: 18px;
  padding: 8px 14px;
  border: 1px solid rgb(0, 0, 0);
}

.contact-form button {
  align-self: center;
  padding: 8px;
  outline: 0;
  border: 0;
  border-radius: 8px;
  font-size: 1.5em;
  font-weight: 500;
  background-color: black;
  color: #fff;
  cursor: pointer;
  font-family: "Playfair Display", serif;
}
    

._1yCVn, ._1qse9{
  z-index: 999999 !important;
}

._2qp0Z{
  background-color: rgb(0, 0, 0) !important;
  color: white !important;
  z-index: 9999 !important;
}

._2qp0Z:hover {
  background-color: rgb(45, 126, 54) !important;
}

/* rgb(79, 206, 93) */

.w-md-editor-text-input {
  margin: 0px !important;
  padding-right: 80px !important;
  /* padding-inline: 0px !important; */
  border: none;
  width: 100% !important;
}

.loader-container{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flag-section{
  width: 25px;
  height: 25px;
  margin: 5px;
  transition: all 0.2s ease-in;
}
.flag-section:hover{
  cursor: pointer;
  transform: scale(1.2);
}
.btn {
  border: none;
  outline: none;
  padding: 1rem;
  transition: all ease 300ms;
  &-sm {
    padding: 10px;

    &:hover {
      cursor: pointer;
      background-color: black;
      color: white;
      transition: all ease 300ms;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: black;
    color: white;
    transition: all ease 300ms;
  }
}

.oader .btn-alt {
  /*  border: solid 5px #fc5185; */
  border: 1px solid white;
  transition: all 0.2s linear;
  outline: none;
  background-color: white;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid black;
  }
}

.lg-img {
  height: 600px;
}

.contrast {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.alt-font {
  font-family: "Playfair Display", serif;
}

/* global classes */

.header-main {
  height: 75vh;
  position: relative;

  .header-content {
    color: white;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 2;
    font-size: 1.8rem;
    h2,
    p {
      z-index: 10;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.desc {
  margin: 7rem auto 0 auto;
  text-align: center;
  max-width: 800px;
  width: 100%;
  h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
}

.desc_main {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  max-width: 1100px;
  position: relative;
  margin: 6rem auto 0 auto;
  .bg-light {
    position: absolute;
    top: -30px;
    left: -42px;
    height: 550px;
    width: 100%;
    max-width: 980px;
    background-color: rgba(0, 0, 0, 0.02);
    z-index: -1;
  }
  .descLeft {
    margin-right: 1rem;
    h1,
    h2 {
      margin: 1rem 0;
    }
  }
  .descRight {
    img {
      width: 100%;
    }
  }
}
.desc_sec {
  padding: 6rem 8rem;
  display: flex;
  margin: 4rem auto 0 auto;
  align-items: center;
  background-color: #f7f7f7;
  .img-container {
    flex-basis: 60%;
  }
  article {
    height: 100%;
    padding-left: 4rem;
    flex-basis: 40%;
    text-align: center;
    div {
      padding: 25% 0;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    span {
      border-bottom: 1px solid black;
      width: 100%;
      display: inline-block;
      margin: 1rem auto;
    }
  }
}

.desc_gallery {
  position: relative;
  max-width: 1200px;
  margin: 4rem auto 0 auto;
  display: flex;
  .descLeft {
    flex-basis: 60%;
    h2 {
      font-size: 3rem;
      font-weight: lighter;
    }
    .img-container {
      padding: 3rem;
    }
  }
  .descRight {
    flex-basis: 30%;

    article {
      padding: 1rem;
      p {
        margin-top: 1rem;
        line-height: 1.6;
      }
    }
  }
}

.desc_photo {
  position: relative;
  max-width: 1100px;
  margin: 6rem auto 4rem auto;
  display: flex;
  h1 {
    font-size: 4rem;
    font-weight: lighter;
    position: absolute;
    top: 0;
    left: -3%;
  }
  .descLeft {
    width: 70%;
    background-color: #f7f7f7;
    padding: 9rem 16rem 3rem 13rem;
    p {
      line-height: 1.6;
    }
  }
  .descRight {
    width: 43%;
    top: -25px;
    right: 0;
    position: absolute;
  }
}

.card {
  width: 100%;
  max-width: 1000px;
  text-align: center;
  margin: 2rem auto 0 auto;
  padding: 2rem;
  background-color: #dbd5cd;
  .info {
    display: flex;
    flex-direction: column;
    text-align: center;
    div {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
    }
  }

  .actions {
    .delete-btn {
      color: red;
      outline: none;
      border: 2px solid red;
      padding: 8px 10px;
      background-color: transparent;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
/* Loader */
.loader {
  margin: auto;
  text-align: center;
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: black;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1200px) {
  .desc_photo {
    .descLeft {
      h1 {
        left: 1%;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .desc_main {
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
    .bg-light {
      height: 110%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    width: auto;
    margin: 2rem 1rem 0 1rem;
  }
}

@media only screen and (max-width: 980px) {
  .desc_photo {
    .descLeft {
      padding: 7rem 11rem 3rem 7rem;
      h1 {
        font-size: 3rem;
        width: 50%;
      }
      p {
        margin-top: 2rem;
      }
    }
  }
}
/* Tablet */
@media only screen and (max-width: 768px) {
  .header-main {
    .header-content {
      text-align: center;
    }
  }
  .desc {
    padding: 0 1rem;
  }
  .desc_main {
    display: flex;
    flex-direction: column-reverse;
    .bg-light {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .desc_sec {
    flex-direction: column;
    article {
      padding-left: 0;
      div {
        padding: 2rem 0;
      }
    }
  }
  .desc_gallery {
    flex-direction: column;
    .descLeft {
      text-align: center;
      .img-container {
        padding: 1rem;
      }
    }
    .descRight {
      padding: 1rem;
    }
  }
  .desc_photo {
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
    margin: 0;
    .descLeft {
      width: 100%;
      padding: 1rem;
      h1 {
        position: relative;
        left: 0;
        top: 0;
      }
    }
    .descRight {
      position: relative;
      width: 100%;
      top: 0;
      right: 0;
    }
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .desc {
    h1 {
      font-size: 1.8rem;
    }
  }
  .header-main {
    height: 50vh;
    .header-content {
      h2 {
        font-size: 1.3rem;
      }
      p {
        font-size: 18px;
        padding: 0 2rem;
      }
    }
  }
  .card {
    padding: 1rem;
    .info {
      h1 {
        font-size: 1.4rem;
      }
      div {
        flex-direction: column;
        align-items: baseline;
        word-break: break-all;
      }
    }
  }
  .desc_photo {
    .descLeft {
      h1 {
        font-size: 1.5rem;
        width: 100%;
      }
    }
  }
}

// .ReactModal__Content {
//   background: red!important;
// }
.ReactModal__Overlay {
  z-index: 99999 !important;
  background-color: #02011646 !important;
  
}

.hoverimagepic{

}

.hoverimagepic:hover{
  transform: scale(1.05);
}

.admin-modal-tables{

  table {
    border-collapse: collapse;
    font-family: Tahoma, Geneva, sans-serif;
    display: block;
    width: 100%;
  }
  table tbody{

  }
  table td {
    padding: 15px;
  }
  table thead td {
    background-color: #54585d;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    border: 1px solid #54585d;
  }
  table tbody td {
    color: #636363;
    border: 1px solid #dddfe1;
  }
  table tbody tr {
    background-color: #f9fafb;
  }
  table tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
}