.Tour {
  img {
    width: 100%;
  }
  .lg-img {
    height: 100%;
  }
}
@media only screen and (max-width: 1100px) {
  .desc_sec {
    padding: 3rem 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .Tour {
    img {
      min-height: 100%;
    }

    .desc_gallery {
      .descLeft {
        .img-container {
          padding: 1rem 1rem 0 1rem;
        }
      }
    }
  }
}
